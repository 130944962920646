import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import WaveSurfer from 'wavesurfer.js';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import { fmtMSS } from '../../../utils/formatters';
import { generateWaveForm } from '../../../utils/waveform-utils';
import './Waveform.scss';
import { Col, Row } from 'layout/components/grid';
import { Avatar } from 'layout/components/avatar';
import StartAudioContext from 'startaudiocontext/StartAudioContext';
import { useFetch } from '../../../hooks/useFetch';
import playBtn from '../../../assets/icons/player-main-btn.svg';
import PlayerButtonMain from '../player-button/PlayerButtonMain';
import PrevButton from './PrevButton';
import NextButton from './NextButton';

let linGrad = null;

if (typeof document !== 'undefined') {
  linGrad = document.createElement('canvas').getContext('2d').createLinearGradient(0, 0, 0, 70);
  linGrad.addColorStop(0, '#000DFF');
  linGrad.addColorStop(1, '#FF00E5');
}

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#DCDCDC',
  progressColor: linGrad,
  cursorColor: 'transparent',
  backend: 'MediaElement',
  barWidth: 2,
  barGap: 4.5,
  barRadius: 3,
  responsive: false,
  normalize: true,
  height: 60
});

export default function WaveformMain() {
  const [track, setTrack] = useState(null);
  const [tracks, setTracks] = useState([]);

  useFetch('/v1/TrackListMain', null, (data) => {
    setTrack(data.tracks && data.tracks[0]);
    setTracks(data.tracks || []);
  });
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const songRef = useRef(null);

  const [url, setUrl] = useState('');
  const [waveform, setWaveform] = useState([]);
  const [time, setTime] = useState(0);

  const isMobile = useMediaQuery(
    { maxWidth: 576 } // `device` prop
  );

  const [playing, setPlaying] = useState(false);

  useIsomorphicLayoutEffect(() => {
    if (!track) {
      return;
    }
    const url = track.track;

    if (!url) {
      return;
    }
    setUrl(url);
    setWaveform(generateWaveForm(track.waveform));
  }, [track]);

  useEffect(() => {
    return () => {
      if (wavesurfer && wavesurfer.current) {
        // Removes events, elements and disconnects Web Audio nodes.
        // when component unmount
        wavesurfer.current.destroy();
      }
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (wavesurfer && wavesurfer.current && wavesurfer.current.backend) {
      if (playing) {
        wavesurfer.current.play();
      } else {
        wavesurfer.current.pause();
      }
    }
  }, [playing]);

  useIsomorphicLayoutEffect(() => {
    if (waveform.length && !!window) {
      const options = formWaveSurferOptions(waveformRef.current);
      if (wavesurfer && wavesurfer.current) {
        wavesurfer.current.destroy();
      }
      wavesurfer.current = WaveSurfer.create(options);
      wavesurfer.current.load(songRef.current, waveform);
      wavesurfer.current.on('ready', function () {
        if (wavesurfer.current) {
          wavesurfer.current.setVolume(1);
          if (playing) {
            wavesurfer.current.play();
          }
        }
      });

      wavesurfer.current.on('finish', function () {
        wavesurfer.current.pause();
      });

      wavesurfer.current.on('audioprocess', function () {
        if (wavesurfer.current.backend && wavesurfer.current.isPlaying()) {
          const currentTime = parseInt(wavesurfer.current.getCurrentTime());
          if (time !== currentTime && wavesurfer.current.backend) {
            setTime(currentTime);
          }
        }
      });
      StartAudioContext(wavesurfer.current.backend.getAudioContext(), '.audiocontext');
    }
  }, [waveform]);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const shift = (index) => {
    if (!tracks.length) {
      return;
    }
    const currentIndex = tracks.findIndex((i) => i.session_id === track.session_id);
    const nextIndex = (currentIndex + index + tracks.length) % tracks.length;
    setTrack(tracks[nextIndex]);
  };
  return track ? (
    <>
      <div className='logo-need-more'>
        <Avatar size={60} src={track.image_url} />
        <p className='main-body-text'>{track.name}</p>
      </div>
      <Row className='waveform-wrapper-row' align='middle'>
        {!isMobile && (
          <Col span={2}>
            <span className='player-time'>{fmtMSS(time)}</span>
          </Col>
        )}
        <Col span={isMobile ? 24 : 20}>
          <div ref={waveformRef} className='waveform-player-block'>
            <audio ref={songRef} style={{ display: 'none' }} preload='false' src={url} />
          </div>
        </Col>
        {!isMobile && (
          <Col span={2}>
            <span className='player-time'>{fmtMSS(track.duration)}</span>
          </Col>
        )}
      </Row>
      <div style={{ paddingTop: 40 }}>
        <div className='player-main-controls'>
          <div className='inline' onClick={() => shift(-1)}>
            <PrevButton />
          </div>
          <PlayerButtonMain
            className={'player-btn player-button inline audiocontext'}
            url={playBtn}
            playing={playing}
            focused={true}
            size={70}
            onClick={handlePlayPause}
            name={track?.name}
          />
          <div className='inline' onClick={() => shift(1)}>
            <NextButton />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div ref={waveformRef} />
  );
}
