import React from 'react';
import { Col, Row } from 'layout/components/grid';
import LogoBanuba from '../../../assets/img/LogoBanuba.svg';
import LogoBanubaDarkMode from '../../../assets/img/LogoBanuba-dark-mode.svg';
import LogoSleepzy from '../../../assets/img/LogoSleepzy.svg';
import LogoSleepzyDarkMode from '../../../assets/img/LogoSleepzy-dark-mode.svg';
import LogoRestream from '../../../assets/img/LogoRestream.svg';
import LogoRestreamDarkMode from '../../../assets/img/LogoRestream-dark-mode.svg';
import LogoInsight from '../../../assets/img/LogoInsightTimer.svg';
import LogoInsightDarkMode from '../../../assets/img/LogoInsightTimer-dark-mode.svg';
import LogoPWC from '../../../assets/img/LogoPWC.svg';
import LogoPWCDarkMode from '../../../assets/img/LogoPWC-dark-mode.svg';
import LogoCrello from '../../../assets/img/LogoCrello.svg';
import LogoCrelloDarkMode from '../../../assets/img/LogoCrello-dark-mode.svg';
import LogoBetterMe from '../../../assets/img/LogoBetterMe.svg';
import LogoBetterMeDarkMode from '../../../assets/img/LogoBetterMe-dark-mode.svg';
import LogoSleep from '../../../assets/img/LogoSleepCycle.svg';
import LogoSleepDarkMode from '../../../assets/img/LogoSleepCycle-dark-mode.svg';
import LogoAdidas from '../../../assets/img/LogoAdidas.svg';
import LogoAdidasDarkMode from '../../../assets/img/LogoAdidas-dark-mode.svg';
import LogoBBDO from '../../../assets/img/LogoBBDO.svg';
import LogoBBDODarkMode from '../../../assets/img/LogoBBDO-dark-mode.svg';
import LogoGeometry from '../../../assets/img/LogoGeometry.svg';
import LogoGeometryDarkMode from '../../../assets/img/LogoGeometry-dark-mode.svg';
import LogoSenorium from '../../../assets/img/LogoSensorium.svg';
import LogoSenoriumDarkMode from '../../../assets/img/LogoSensorium-dark-mode.svg';

import {LazyImage} from '../../components/lazy-image';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';
import useTranslation from 'hooks/useTranslations';

export function FooterLogoBanner() {
  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  const translate = useTranslation()
 

  return (
    <div className='logo-little footer-logo-banner'>
      <h2 className='main-title footer-logo-text'>{translate("Trusted by some of the world’s biggest brands and organizations")}</h2>
      <Row style={{ marginTop: '40px' }}>
        <Col xl={12} md={24} span={24}>
          <div className='logo-block'>
            <LazyImage src={isDarkTheme ?  LogoBanubaDarkMode : LogoBanuba} alt='LogoBanuba' />
            <LazyImage src={isDarkTheme ? LogoSleepzyDarkMode : LogoSleepzy} alt='LogoSleepzy' />
          </div>
          <div className='logo-block'>
            <LazyImage src={isDarkTheme ? LogoRestreamDarkMode : LogoRestream} alt='LogoRestream' />
            <LazyImage src={isDarkTheme ? LogoInsightDarkMode : LogoInsight} alt='LogoInsight' />
          </div>
          <div className='logo-block'>
            <LazyImage src={isDarkTheme ? LogoPWCDarkMode : LogoPWC} alt='LogoPWC' />
            <LazyImage src={isDarkTheme ? LogoCrelloDarkMode : LogoCrello} alt='LogoCrello' />
          </div>
        </Col>
        <Col xl={12} md={24} span={24}>
          <div className='logo-block'>
            <LazyImage src={isDarkTheme ? LogoBetterMeDarkMode : LogoBetterMe} alt='LogoBetterMe' />
            <LazyImage src={isDarkTheme ? LogoSleepDarkMode : LogoSleep} alt='LogoSleep' />
          </div>
          <div className='logo-block'>
            <LazyImage src={isDarkTheme ? LogoSenoriumDarkMode : LogoSenorium} alt='LogoSenorium' />
            <LazyImage src={isDarkTheme ? LogoAdidasDarkMode : LogoAdidas} alt='LogoAdidas' />
          </div>
          <div className='logo-block'>
            <LazyImage src={isDarkTheme ? LogoBBDODarkMode : LogoBBDO} alt='LogoBBDO' />
            <LazyImage src={isDarkTheme ? LogoGeometryDarkMode : LogoGeometry} alt='LogoGeometry' />
          </div>
        </Col>
      </Row>
    </div>
  );
}
