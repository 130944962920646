import React from 'react';

export default function PrevButton() {
  return (
    <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg" className="waveform-control-btn">
      <rect x="1.79918" y="48.9609" width="1.79917" height="43.7657" rx="0.899584" transform="rotate(180 1.79918 48.9609)" fill="#DCDCDC"/>
      <path d="M32.523 46.1144C32.523 47.7992 30.6757 48.7586 29.3857 47.7438L5.36966 28.8517C4.3315 28.0351 4.3315 26.4096 5.36967 25.5929L29.3857 6.70108C30.6758 5.68631 32.523 6.64571 32.523 8.33048L32.523 27.2225L32.523 46.1144Z" fill="#DCDCDC"/>
    </svg>
  )
}
