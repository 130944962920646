import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import BaseLayout from '../base/BaseLayout';
import Header from '../components/header/Header';

import { setCurrentTrack } from '../../redux/current-track/actions';
import IndexMain from './IndexMain';
import { BottomFooter } from 'layout/components/bottom-footer';

const IndexVertical = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentTrack(null));
  }, []);

  const nav = <Header />;
  const footer = <BottomFooter />;

  return (
    <BaseLayout nav={nav} bottomNav={null} footer={footer} player={null}>
      <IndexMain />
    </BaseLayout>
  );
};

export default IndexVertical;
