import React from 'react';
import { Helmet } from 'react-helmet';
import IndexVertical from 'layout/vertical/IndexVertical';

export default function MainPage() {
  return (
    <>
      <Helmet>
        <title>
          Mubert - Thousands of Staff-Picked Royalty-Free Music Tracks for Streaming, Videos,
          Podcasts, Commercial Use and Online Content
        </title>
        <meta
          name='description'
          content='Mubert - The new royalty-free music ecosystem for content creators, brands and developers 🔥 Come See How Our High-Quality Music Can Elevate Your Content ⏩'
        />
      </Helmet>
      <IndexVertical />
    </>
  );
}
