import React, { useEffect } from 'react';

import { useLocation } from 'hooks/useLocation';
import { Button } from 'layout/components/app-button';
import { Row, Col } from 'layout/components/grid';

import TnwLogo from '../../assets/logo/tnw.svg';
import TnwLogoDarkMode from '../../assets/logo/tnw-dark-mode.svg';
import ForbesLogoDarkMode from '../../assets/logo/Forbes-dark-mode.svg';
import ForbesLogo from '../../assets/logo/Forbes.svg';
import AdweekLogoDarkMode from '../../assets/logo/ADWEEK-dark-mode.svg';
import AdweekLogo from '../../assets/logo/ADWEEK.svg';
import DjsLogo from '../../assets/logo/DJ.svg';
import DjsLogoDarkMode from '../../assets/logo/DJ-dark-mode.svg';
import VentureLogo from '../../assets/logo/VentureBeat.svg';
import VentureLogoDarkMode from '../../assets/logo/VentureBeat-dark-mode.svg';
import WiredLogo from '../../assets/logo/wired.svg';
import WiredLogoDarkMode from '../../assets/logo/wired-dark-mode.svg';
import PlayMarketLogo from '../../assets/logo/google-play.svg';

import LogoIosMarket from '../../assets/img/ios_playmarket.svg';
import MubertCreate from '../../assets/img/image-main-1.jpg';
import MubertCreateWebp from '../../assets/img/image-main-1.webp';
import MubertApiNew from '../../assets/img/image-main-2.jpg';
import MubertApiNewWebp from '../../assets/img/image-main-2.webp';
import MubertPlay from '../../assets/img/image-main-4.jpg';
import MubertPlayWebp from '../../assets/img/image-main-4.webp';
import MubertStudio from '../../assets/img/image-main-3.jpg';
import MubertStudioWebp from '../../assets/img/image-main-3.webp';

import './IndexMain.scss';
import { Link } from 'layout/components/link';
import WaveformMain from '../components/waveform/WaveformMain';
import { FooterLogoBanner } from '../components/footer-logo-banner';
import { LazyImage, LazyPicture } from 'layout/components/lazy-image';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces/app-state';
import { CrossIcon, WhiteBoltIcon } from 'layout/components/icons';
import { MUBERT_API_URL } from 'constants/common';
import { Modals } from 'layout/components/modals';
import useTranslation from 'hooks/useTranslations';

const IndexMain: React.FC = () => {
  const location = useLocation();
  const mubertPlayRef = React.createRef<HTMLDivElement>();
  const playerRef = React.createRef<HTMLDivElement>();
  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  const translate = useTranslation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const scrollTo = query.get('scrollTo');

    if (scrollTo === 'play') {
      setTimeout(() => {
        mubertPlayRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }

    if (scrollTo === 'waveform') {
      setTimeout(() => {
        playerRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }, []);

  return (
    <div className='index-main'>
      <div className='main-header-layout text-center'>
        <h1 className='main-title'>
          <div>
            <span className='rainbow-text'>Human</span>
          </div>
          <CrossIcon />
          <div>
            <span className='rainbow-text'>AI Music Generator</span>
          </div>
        </h1>
        <p className='small-subtitle small-subtitle__blue desktop-only'>
          {translate('For your video content, podcasts and apps')}
        </p>
        <Link to='/render' title="Royalty-Free Music for modern creators & apps">
          <Button className='generate-track-button'>
            <WhiteBoltIcon />
            {translate('Generate a track now')}
          </Button>
        </Link>
        <p className='small-subtitle small-subtitle__blue tablet-only'>
          {translate('For your video content, podcasts and apps')}
        </p>
      </div>
      <div className='main-projects-layout'>
        <div className='main-projects-layout__wrapper'>
          <div className='card card__mubert-render'>
            <h2 className='main-title rainbow-text'>
              <span className='rainbow-text'>{translate('Mubert Render')}</span>
            </h2>
            <p className='project-subtitle'>{translate('For content creators')}</p>
            <div className='small-subtitle'>
              {translate(
                'Generate AI soundtrack that will fit your content’s mood and duration. Instantly, easily, perfectly.'
              )}
            </div>
            <div className='submit-button-container'>
              <Link to='/render' title="Royalty-Free Music for modern creators & apps">
                <Button type='primary'>{translate('Make a track now')}</Button>
              </Link>
            </div>
          </div>
          <div className='card card__mubert-studio'>
            <h2 className='main-title rainbow-text'>
              <span className='rainbow-text'>{translate('Mubert Studio')}</span>
            </h2>
            <p className='project-subtitle'>{translate('For artists')}</p>
            <div className='small-subtitle'>
              {translate(
                'Earn money by contributing samples and loops. Team up with AI to produce something incredible.'
              )}
            </div>
            <div className='submit-button-container'>
              <a href='https://beta.mubert.com' target='_blank' rel='noopener noreferrer nofollow'>
                <Button type='primary'>{translate('Contribute')}</Button>
              </a>
            </div>
          </div>
          <div className='card card__mubert-api'>
            <h2 className='main-title rainbow-text'>
              <span className='rainbow-text'>{translate('Mubert API')}</span>
            </h2>
            <p className='project-subtitle'>{translate('For developers and brands')}</p>
            <div className='small-subtitle'>
              {translate(
                'Get original AI music for your product, stress free, royalty free. Use personalized AI experience to grow your business.'
              )}
            </div>
            <div className='submit-button-container'>
              <a href={MUBERT_API_URL} target='_blank' rel='noopener noreferrer nofollow'>
                <Button type='primary'>{translate('Request a demo')}</Button>
              </a>
            </div>
          </div>
          <div className='card card__mubert_play'>
            <h2 className='main-title'>
              <span className='rainbow-text'>{translate('Mubert Play')}</span>
            </h2>
            <p className='project-subtitle '>{translate('For listeners')}</p>
            <div className='small-subtitle'>
              {translate('Find AI tunes to suit any moment.')}
              <br />
              {translate('Chill, train, work or just listen.')}
            </div>
            <div className='submit-button-container'>
              <a
                href='https://playapp.mubert.com/?utm_source=mubert.com&utm_medium=cpc&utm_content=mubert_play'
                target='_blank'
                rel='noopener noreferrer nofollow'
              >
                <Button type='primary'>{translate('Listen in the app')}</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='logo-little logos-main'>
        <img src={isDarkTheme ? TnwLogoDarkMode : TnwLogo} alt='LogoBanuba' />
        <img src={isDarkTheme ? ForbesLogoDarkMode : ForbesLogo} alt='LogoPicsArt' />
        <img src={isDarkTheme ? AdweekLogoDarkMode : AdweekLogo} alt='LogoBetterMe' />
        <img src={isDarkTheme ? DjsLogoDarkMode : DjsLogo} alt='LogoSleep' />
        <img src={isDarkTheme ? VentureLogoDarkMode : VentureLogo} alt='LogoSleep' />
        <img src={isDarkTheme ? WiredLogoDarkMode : WiredLogo} alt='LogoSleep' />
      </div>
      <div className='main-music-made'>
        <h2 className='main-title '>{translate('AI Music has no boundaries')}</h2>
        <p className='small-subtitle'>
          {translate(
            "The abilities of Artificial Intelligence and the creativity of music producers make possible a symbiotic relationship between humans and the algorithms. Millions of samples from hundreds of artists are used by Mubert to instantly generate royalty-free AI music, flawlessly suited to the content's purpose. Collaboration like no other, humans and technology unite to bring the perfect sound every time."
          )}
        </p>
      </div>
      <div className='need-more-wrapper'>
        <div className='create-with-mubert music-given'>
          <Row align='middle'>
            <div className='create-with-mubert__image'>
              <LazyPicture
                className='create-with-mubert-image'
                alt='mubert-render'
                images={[
                  { url: MubertCreateWebp, type: 'image/webp', sizes: '(max-width: 600px) 540px, 1080px'  },
                  { url: MubertCreate, type: 'image/jpeg', main: true }
                ]}
                height='auto'
              />
            </div>
            <div>
              <h2 className='main-title'>{translate('Create with Mubert Render')}</h2>
              <p className='small-subtitle'>
                {translate("The music you've been searching for, generated in seconds.")}
              </p>
              <div className='main-body-text'>
                {translate(
                  'Effortlessly generate an AI soundtrack of the perfect length, mood and style. Mubert instantly generates tracks perfectly tailored to your content on any platform. Royalty-free background music for YouTube, Tik Tok, podcasts and video!Select the parameters of your future soundtrack in a matter of seconds, and generate extraordinary music right away.'
                )}
              </div>
              <div className='create-buttons'>
                <Link to='/render' title="Royalty-Free Music for modern creators & apps">
                  <Button className='submit-button' htmlType='submit' type='primary'>
                    {translate('Create a track for free')}
                  </Button>
                </Link>
              </div>
            </div>
          </Row>
        </div>
      </div>
      <div className='need-more-wrapper' ref={playerRef as React.RefObject<HTMLDivElement>}>
        <div className='main-need-more'>
          <h2 className='main-title'>{translate('Need more inspiration?')}</h2>
          <p className='small-subtitle'>
            {translate('Here’s what other creators made in just 3 clicks:')}
          </p>
          <div className='waveform-wrapper'>
            <WaveformMain />
          </div>
          <Link to='/render' title="Royalty-Free Music for modern creators & apps">
            <Button className='submit-button' htmlType='submit' type='primary'>
              {translate('Make a track now')}
            </Button>
          </Link>
        </div>
      </div>
      <div className='need-more-wrapper'>
        <div className='create-with-mubert'>
          <Row>
            <div className='create-with-mubert__image'>
              <LazyPicture
                className='create-with-mubert-image'
                images={[
                  { url: MubertApiNewWebp, type: 'image/webp' },
                  { url: MubertApiNew, type: 'image/jpeg', main: true }
                ]}
                alt='mubert-api'
                height='auto'
              />
            </div>
            <div>
              <h2 className='main-title'>{translate('Develop with Mubert API')}</h2>
              <p className='small-subtitle'>{translate('Bring music to your software product.')}</p>
              <div className='main-body-text'>
                {translate(
                  'Integrate Mubert to enhance your app or game. Generate AI soundtracks that suit your brand and bring an infinite amount of quality audio material to your platform. Get royalty-free music to use commercially however you like.'
                )}
                <br />
                <br />
                {translate(
                  'Tell us about your project, get a demonstration of how Mubert could work with your business.'
                )}
              </div>
              <a href={MUBERT_API_URL} target='_blank' rel='noopener noreferrer nofollow'>
                <Button className='submit-button' type='primary'>
                  {translate('Request API access')}
                </Button>
              </a>
            </div>
          </Row>
        </div>
      </div>
      <div className='need-more-wrapper'>
        <div className='create-with-mubert create-and-earn-with-mubert'>
          <Row align='middle' reverse>
            <div>
              <h2 className='main-title studio'>
                {translate('Create and earn with Mubert Studio')}
              </h2>
              <p className='small-subtitle'>{translate('Don’t waste a single beat.')}</p>
              <div className='main-body-text'>
                {translate(
                  'Become a Mubert Studio musician to unlock the infinite potential of your music with AI. Get paid for riffs, loops and sample packs that are sitting on your hard drive. Reach more people with your AI generated music and make every sound count.'
                )}
                <br />
                <br />
                {translate(
                  'Contribute to Mubert, make money, and help create something incredible.'
                )}
              </div>
              <a href='https://beta.mubert.com/' target='_blank' rel='noopener noreferrer nofollow'>
                <Button className='submit-button' htmlType='submit' type='primary'>
                  {translate('Start contributing')}
                </Button>
              </a>
            </div>
            <div className='create-with-mubert__image'>
              <LazyPicture
                images={[
                  { url: MubertStudioWebp, type: 'image/webp' },
                  { url: MubertStudio, type: 'image/jpeg', main: true }
                ]}
                alt='create-and-earn'
                className='create-with-mubert-image'
                height='auto'
              />
            </div>
          </Row>
        </div>
      </div>
      <div className='need-more-wrapper'>
        <div className='create-with-mubert listen-with-mubert'>
          <Row align='middle' ref={mubertPlayRef as React.RefObject<HTMLDivElement>}>
            <div className='create-with-mubert__image'>
              <LazyPicture
                images={[
                  { url: MubertPlayWebp, type: 'image/webp' },
                  { url: MubertPlay, type: 'image/jpeg', main: true }
                ]}
                alt='mubert-play'
                height='auto'
                className='create-with-mubert-image'
              />
            </div>
            <div className='create-with-mubert__content'>
              <h2 className='main-title'>{translate('Listen with Mubert Play')}</h2>
              <p className='small-subtitle'>
                {translate('An evolving AI music experience for any occasion.')}
              </p>
              <div className='main-body-text'>
                {translate(
                  'Mubert Play creates an infinite AI soundtrack for each of life’s moments. Select from dozens of different moods - from Calm Meditation to Sports Extreme, and let Mubert do the magic. No two moments are the same, each stream is an infinite channel of brand new AI music assembled just for you.'
                )}
                <br />
                <br />
                {translate('Whatever you’re up to, Mubert is there with the soundtrack to match.')}
              </div>
              <div className='ios-market-btn'>
                <a
                  href='https://playapp.mubert.com/?src=ios&utm_source=mubert.com&utm_medium=cpc&utm_content=apple_store'
                  rel='noopener noreferrer nofollow'
                >
                  <LazyImage src={LogoIosMarket} alt='LogoIosMarket' height={67} width={199} />
                </a>
                <a
                  href='https://playapp.mubert.com/?src=android&utm_source=mubert.com&utm_medium=cpc&utm_content=google_store'
                  target='_blank'
                  rel='noopener noreferrer nofollow'
                >
                  <LazyImage src={PlayMarketLogo} alt='LogoIosMarket' width={266} height={102} />
                </a>
              </div>
            </div>
          </Row>
        </div>
      </div>
      <FooterLogoBanner />
      <Modals />
    </div>
  );
};

export default IndexMain;
