import React from 'react';

export default function NextButton() {
  return (
    <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg" className="waveform-control-btn">
      <rect x="52.9459" y="5.48389" width="1.79917" height="43.7657" rx="0.899584" fill="#DCDCDC"/>
      <path d="M22.2221 8.33042C22.2221 6.64565 24.0693 5.68626 25.3593 6.70104L49.3754 25.5931C50.4136 26.4098 50.4136 28.0352 49.3754 28.8519L25.3593 47.7437C24.0693 48.7585 22.2221 47.7991 22.2221 46.1143L22.2221 27.2224L22.2221 8.33042Z" fill="#DCDCDC"/>
    </svg>
  )
}
